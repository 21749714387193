import React, {useEffect, useState} from "react";
import "./Header.css"
import axios from "axios";
import API_URL from "../../index";

const Header = ({role, onRoleChange, setChangedCity}) => {
    const windowWidth = window.innerWidth;
    const [login, setLogin] = useState(false);
    // const [city, setCity] = useState();
    const [usersData, setUsersData] = useState({})
    const [menuOpen, setMenuOpen] = useState(false);

    const [inputCityValue, setInputCityValue] = useState("Москва");
    const [filteredCityOptions, setFilteredCityOptions] = useState([]);
    const [showCityOptions, setShowCityOptions] = useState(false);
    const [sortedCities, setSortedCities] = useState([])

    useEffect(() => {
        if (menuOpen) {
            document.body.style.overflow = 'hidden'; // Блокируем скролл страницы
        } else {
            document.body.style.overflow = ''; // Восстанавливаем скролл
        }

        // Очистка эффекта при размонтировании компонента
        return () => {
            document.body.style.overflow = '';
        };
    }, [menuOpen]);

    const me = async () => {
        if (window.location.pathname !== "/authorization" && window.location.pathname !== "/registration") {
            await axios.get(`${API_URL}/user/me`, {withCredentials: true})
                .then(response => {
                    if (response.status === 200) {
                        setLogin(true)
                        // setCity(response.data["city"]["name"])
                        setInputCityValue(response.data["city"]["name"])
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 403) {
                        console.warn("Вы не авторизованы.");
                        setLogin(false);
                        setInputCityValue("Москва");
                        // window.location.href = "/authorization"
                    } else {
                        console.log(error)
                    }
                })
        }
    }

    const change_city = async (option) => {
        if (login) {
            if (option) {
                await axios.post(`${API_URL}/user/change-city?city=${option}`)
                    .then(response => {
                        if (response.status === 200) {
                            setShowCityOptions(false)
                            window.location.reload()
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        } else {
            if (option) {
                setChangedCity(option);
            }
        }
    }

    const get_count_applications_month_year = async () => {
        await axios.get(`${API_URL}/application/get-count-applications-month-year`, {withCredentials: true})
            .then(response => {
                if (response.status === 200) {
                    setUsersData(response.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const open_menu = () => {
        const menu = document.querySelector(".menu-container")
        const black = document.querySelector(".window-closer")
        if (menu.classList.contains("open")) {
            menu.classList.remove("open");
            black.classList.remove("open")
            setMenuOpen(false)
        } else {
            menu.classList.add("open");
            black.classList.add("open")
            setMenuOpen(true)
        }
    }

    const logout = async () => {
        await axios.post(`${API_URL}/user/logout`)
            .then(response => {
                if (response.status === 200 || 201) {
                    window.location.href = "/authorization"
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleOptionClick = async (option, type) => {
        if (type === "city") {
            await change_city(option)
            setInputCityValue(option);
            setShowCityOptions(false);
        }
    };

    const handleImageClick = (type) => {
        if (type === "city") {
            setShowCityOptions(prevState => !prevState);
            setFilteredCityOptions(sortedCities);  // показываем все опции при клике на иконку
        }
    };

    const handleFocus = async (type) => {
        if (type === "city") {
            setShowCityOptions(true);
            setFilteredCityOptions(sortedCities);  // показываем все опции при фокусировке на input
        }
    };

    const get_city = async () => {
        try {
            await axios.get(`${API_URL}/application/get-cities?offset=0`)
                .then(response => {
                    if (response.status === 200) {
                        const cities = response.data;
                        const options = cities.map(city => city.name);
                        const sortedOptions = options.sort((a, b) => a.localeCompare(b))
                        setSortedCities(sortedOptions)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = (e, type) => {
        const value = e.target.value;
        if (type === "city") {
            setInputCityValue(value);
            setFilteredCityOptions(sortedCities.filter(option =>
                option.toLowerCase().includes(value.toLowerCase())
            ));
            setShowCityOptions(true);
        }
    };

    useEffect(() => {
        me()
        get_count_applications_month_year()
        get_city()
    }, [])

    return (
        <>
            <div className="header">
                <div className="pre-header">
                    <h2 className="pre-header__title">Быстро найдите исполнителя на ваше объявление</h2>
                </div>
                <div className="container header-container">
                    <div className="header-base">
                        <div className="header-base__row">
                            <div className="header__title-a-city">
                                <h1 className="header__title" onClick={() => window.location.href = "/"}>Услуги
                                    24/7</h1>
                                {window.location.pathname !== "/authorization" && window.location.pathname !== "/registration" && window.location.pathname !== "/account" ? (
                                    <div className="header__city-container">
                                        <img src="/location.svg" alt="Location" className="header__city-img"/>
                                        <div className="city__input-container" onClick={() => handleImageClick("city")}>
                                            <input
                                                type="text"
                                                className="city__input"
                                                readOnly
                                                value={inputCityValue}
                                                onChange={(event) => handleChange(event, "city")}
                                                onFocus={(event) => handleFocus(event, "city")}
                                                onBlur={() => setTimeout(() => setShowCityOptions(false), 100)} // delay to allow click
                                            />
                                            {showCityOptions && filteredCityOptions.length > 0 ? (
                                                <div className="add-applic__options-container">
                                                    {filteredCityOptions.map((option, index) => (
                                                        <div
                                                            key={index}
                                                            className="add-applic__option"
                                                            onClick={() => handleOptionClick(option, "city")}
                                                        >
                                                            {option}
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : inputCityValue ? showCityOptions && filteredCityOptions.length === 0 && (
                                                <div className="add-applic__options-container">
                                                    <div className="add-applic__option">Такого города не найдено</div>
                                                    {/*<img className="add-applic__dropdown-input-img city"*/}
                                                    {/*     src="/arrow-down.svg" alt="Open" onClick={() => handleImageClick("city")}*/}
                                                    {/*/>*/}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="header__nav">
                                {/*{window.location.pathname !== "/authorization" && window.location.pathname !== "/registration" ? (*/}
                                <ul className="header__nav-list">
                                    <li className="header__nav-list__item">
                                        {windowWidth > 480 && (
                                            <>
                                                <button onClick={() => {
                                                    if (login) {
                                                        window.location.href = "/applications"
                                                    } else {
                                                        window.location.href = "/authorization"
                                                    }
                                                }}
                                                        className="button__menu">
                                                    <img src="/header-star.svg" alt="Applic"
                                                         className="menu__icon"/>
                                                </button>
                                                <button onClick={() => {
                                                    if (login) {
                                                        window.location.href = "/account"
                                                    } else {
                                                        window.location.href = "/authorization"
                                                    }
                                                }}
                                                        className="button__menu">
                                                    <img src="/header-human.svg" alt="Account"
                                                         className="menu__icon"/>
                                                </button>
                                            </>
                                        )}
                                        <button className="button__menu" onClick={open_menu}>
                                            <img src="/menu.svg" alt="Menu" className="menu__icon"/>
                                        </button>
                                    </li>
                                </ul>
                                {/*) : null}*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="window-closer" onClick={open_menu}></section>
            <div className="menu">
                <div className="container menu-container">
                    <div className="menu-header">
                        <button className="menu__back-btn" onClick={open_menu}>
                            <img src="/arrow-menu.svg" alt="Back" className="menu__back-img"/>
                        </button>
                        <h3 className="menu__title">Информация</h3>
                    </div>
                    {windowWidth <= 480 && login && (
                        <>
                            <button className="menu__link" onClick={() => window.location.href = "/account"}>
                                Профиль
                            </button>
                            <button className="menu__link" onClick={() => window.location.href = "/applications"}>
                                Мои объявления
                            </button>
                        </>
                    )}
                    <div className="menu__info">
                        <div className="menu__info-first">
                            <img src="/peoples-menu.svg" alt="Peoples" className="menu__info-icon"/>
                            <h2 className="menu__info-count">{usersData["users"]}</h2>
                            <h3 className="menu__info-title">Зарегистрированных пользователей</h3>
                        </div>
                        <div className="menu__info-two">
                            <div className="menu__info-first">
                                <img src="/blocks-menu.svg" alt="Month" className="menu__info-icon"/>
                                <h2 className="menu__info-count">{usersData["month"]}</h2>
                                <h3 className="menu__info-title">Объявлений
                                    в месяц</h3>
                            </div>
                            <div className="menu__info-first">
                                <img src="/history-menu.svg" alt="Age" className="menu__info-icon"/>
                                <h2 className="menu__info-count">{usersData["year"]}</h2>
                                <h3 className="menu__info-title">Объявлений
                                    в год</h3>
                            </div>
                        </div>
                    </div>
                    <div className="menu__links">
                        {window.location.pathname === "/" && (
                            <button className="menu__link role" onClick={onRoleChange}>
                                Я - {role === 'client' ? 'Заказчик' : 'Исполнитель'}
                            </button>
                        )}
                        <button className="menu__link">
                            <img src="/tg-menu.svg" alt="Telegram" className="menu__link-tg"/>
                            ТехПоддержка
                        </button>
                        <button className="menu__link" onClick={() => window.location.href = "/rules"}>Правила Услуги
                            24/7
                        </button>
                        <button className="menu__link"
                                onClick={() => window.location.href = "/services-agreement"}>Оферта на оказание
                            услуг
                        </button>
                        <button className="menu__link" onClick={() => window.location.href = "/privacy-policy"}>Политика
                            конфиденциальности
                        </button>
                        <button className="menu__link"
                                onClick={() => {
                                    if (login) {
                                        window.location.href = "/authorization"
                                        logout()
                                    } else {
                                        window.location.href = "/authorization"
                                    }
                                }}>{login ? "Выйти" : "Войти"}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header