import React, {useEffect, useState} from "react";
import "./HomePageForClient.css"

import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore from 'swiper';
import {Navigation} from "swiper/modules";
import AddBlock from "../../../components/AddBlock/AddBlock";
import axios from "axios";
import API_URL from "../../../index";

SwiperCore.use([Navigation]);

const HomePageForClient = ({windowWidth, categories, selectedCity, login}) => {

    const [selectedCategory, setSelectedCategory] = useState("")
    const [categoryName, setCategoryName] = useState("")
    const [selectedSubCategory, setSelectedSubCategory] = useState("")

    const open_add_applic = (index, slide_index) => {
        const section = document.querySelector(".add-applic")
        const black = document.querySelector(".window-closer-applic")
        if (index >= 0) {
            setSelectedCategory(categories[index]["sub_categories"]) //index
            setCategoryName(categories[index]["name"])
            setSelectedSubCategory(categories[index]["sub_categories"][slide_index]["name"])
        } else {
            setSelectedCategory(undefined)
            setCategoryName("")
            setSelectedSubCategory(undefined)
        }
        if (section.classList.contains("open")) {
            section.classList.remove("open");
            black.classList.remove("open");
        } else {
            section.classList.add("open");
            black.classList.add("open");
        }
    }

    return (
        <>
            <div className="homepage">
                <div className="container homepage-container">
                    {categories && (
                        <div className="homepage__column">
                            {categories.map((item, index) => (
                                <div style={{width: "100%"}} key={index}>
                                    <h2 className="category__title">{item["sub_categories"].length > 0 && item["name"]}</h2>
                                    <div className="homepage__category-list">
                                        <Swiper
                                            className="swiper-container"
                                            spaceBetween={windowWidth > 780 ? -30 : 0} // Задает расстояние между слайдами
                                            slidesPerView={windowWidth > 780 ? 3 : windowWidth > 540 ? 2 : 1} // Количество видимых слайдов
                                            slidesPerGroup={windowWidth > 780 ? (item["sub_categories"].length > 5 ? 3 : 2) : windowWidth > 540 ? 2 : 1}
                                            centeredSlidesBounds={true}
                                            centeredSlides={item["sub_categories"].length < 3}
                                            direction={'horizontal'}
                                            navigation={{
                                                nextEl: '.swiper-button-next',
                                                prevEl: '.swiper-button-prev',
                                            }}
                                            loop={windowWidth > 780 ? item["sub_categories"].length > 5 : false}
                                            pagination={{clickable: true}}
                                        >
                                            {item["sub_categories"] ? item["sub_categories"].map((slide, slide_index) => (
                                                <SwiperSlide key={slide_index} className="swiper-slide">
                                                    <div className="slide-content">
                                                        <img src={`${API_URL}/api/static/${slide["image"]["path"]}`} alt={`Logo ${slide["name"]}`} className="slide__img"/>
                                                        <h3 className="slide__title">{slide["name"]}</h3>
                                                        <span
                                                            className="slide__description">{slide["description"]}</span>
                                                        <button className="slide__btn"
                                                                onClick={() => {
                                                                    if (login) {
                                                                        open_add_applic(index, slide_index)
                                                                    } else {
                                                                        window.location.href = "/authorization"
                                                                    }
                                                                }}>
                                                            Создать
                                                        </button>
                                                    </div>
                                                </SwiperSlide>
                                            )) : null}
                                            {item["sub_categories"].length > 3 && (
                                                <>
                                                    <div className="swiper-button-prev">
                                                        <img src="/slider-arrow.svg" alt="Prev" className="swiper-img"/>
                                                    </div>
                                                    <div className="swiper-button-next">
                                                        <img src="/slider-arrow.svg" alt="Next"
                                                             className="swiper-img-last"/>
                                                    </div>
                                                </>
                                            )}
                                        </Swiper>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <section className="window-closer-applic" onClick={() => open_add_applic()}></section>
            <div className="add-applic">
                {selectedCategory && (
                    <AddBlock type={"add"} applicationId={null} descriptionEdit={null} selectedSubCategory={selectedSubCategory} listSubCategories={selectedCategory.map(subCategory => subCategory.name)} selectedCity={selectedCity} categoryName={categoryName}/>
                )}
            </div>
        </>
    )
}

export default HomePageForClient