import React, {useEffect, useState} from "react";
import "./Footer.css"
import {Link} from "react-router-dom";
import axios from "axios";
import API_URL from "../../index";

const Footer = () => {
    const [login, setLogin] = useState(false)

    const me = async () => {
        if (window.location.pathname !== "/authorization" && window.location.pathname !== "/registration") {
            await axios.get(`${API_URL}/user/me`, {withCredentials: true})
                .then(response => {
                    if (response.status === 200) {
                        setLogin(true)
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 403) {
                        setLogin(false)
                    } else {
                        console.log(error)
                    }
                })
        }
    }

    useEffect(() => {
        me()
    }, [])

    return (
        <>
            <div className="footer">
                <div className="container footer-container">
                    <div className="footer__column">
                        {/*style={window.location.pathname === "/authorization" || window.location.pathname === "/registration" ? {justifyContent: "center"} : {justifyContent: "space-between"}}>*/}
                        <div className="footer__title-a-btns">
                            <h2 className="footer__title">Услуги 24/7</h2>
                            <div className="footer__btns">
                                <button className="footer__play-market">
                                    <div className="footer__play-market-img-block">
                                        <img src="/google-play.svg" alt="PlayMarket"
                                             className="footer__play-market-img"/>
                                    </div>
                                    <span className="footer__play-market-text">Скачать из<br/>Google Play</span>
                                </button>
                                <button className="footer__rustore">
                                    <img src="/rustore.svg" alt="RuStore" className="footer__play-market-img"/>
                                    <span className="footer__play-market-text">Скачать из<br/>RuStore</span>
                                </button>
                            </div>
                        </div>
                        <>
                            <div className="footer__help">
                                <h3 className="footer__title-h3">Помощь</h3>
                                {window.location.pathname !== "/authorization" && window.location.pathname !== "/registration" && (
                                    <Link to={login ? "/account" : "/authorization"} className="footer__link-item">Мой
                                        аккаунт</Link>
                                )}
                                <Link to="/rules" className="footer__link-item">Правила Услуги 24/7</Link>
                                <Link to="#" className="footer__link-item">
                                    <img src="/footer-tg.svg" alt="Telegram" className="footer__link-tg-logo"/>
                                    ТехПоддержка
                                </Link>
                            </div>
                            <div className="footer__company">
                                <h3 className="footer__title-h3">Компания</h3>
                                {/*<Link to="#" className="footer__link-item">О нас</Link>*/}
                                <Link to="/services-agreement" className="footer__link-item">Оферта на оказание
                                    услуг</Link>
                                <Link to="/privacy-policy" className="footer__link-item">Политика
                                    конфиденциальности</Link>
                            </div>
                        </>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer